import useMediaQuery from '@mui/material/useMediaQuery';
import Modal from '@mui/material/Modal';

export default function CmdkModal({ children, showCMDK, setShowCMDK }) {
  const isMobile = useMediaQuery('(max-width: 576px)');

  if (isMobile) {
    return (
      <Modal
        open={showCMDK}
        onClose={() => setShowCMDK(false)}
        aria-labelledby="search-modal-title"
        aria-describedby="search-modal-description"
      >
        <div>
          <div className="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-z-50 tw-mt-24 tw-rounded-t-[10px] tw-border-t tw-border-gray-200 tw-bg-white dark:tw-bg-gray-900">
            <div className="tw-sticky tw-top-0 tw-z-20 tw-flex tw-w-full tw-items-center tw-justify-center tw-rounded-t-[10px] tw-bg-inherit">
              <div className="tw-my-3 tw-h-1 tw-w-12 tw-rounded-full tw-bg-gray-300" />
            </div>
            {children}
          </div>
        </div>
      </Modal>
    );
  }
  return (
    <Modal
      open={showCMDK}
      onClose={() => setShowCMDK(false)}
      aria-labelledby="search-modal-title"
      aria-describedby="search-modal-description"
    >
      <div>
        <div className="tw-fixed tw-inset-0 tw-z-40 tw-m-auto tw-w-full tw-max-w-lg tw-animate-scale-in tw-overflow-hidden tw-border tw-border-solid tw-border-gray-200 tw-bg-white dark:tw-bg-gray-900 tw-p-0 tw-shadow-xl md:tw-rounded-2xl tw-max-h-[418px] tw-rounded-xl">
          {children}
        </div>
      </div>
    </Modal>
  );
}
