import { gql } from '@apollo/client';

export const ADD_PUSH_SUBSCRIPTION = gql`
  mutation addPushSubscription($pushSubscription: String!) {
    addPushSubscription(pushSubscription: $pushSubscription) {
      success
      message
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query getNotifications {
    getNotifications {
      actor
      type
      permlink
      rootAuthor
      date
    }
  }
`;
