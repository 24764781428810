// https://codepen.io/adamwathan/pen/qvXGpZ

const shadow = {
  default: '0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06)',
  md: '0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -1px rgba(0, 0, 0, .06)',
  lg: '0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05)',
  xl: '0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,0.07)',
  // xl: '0 20px 25px -5px rgba(0, 0, 0, .1), 0 10px 10px -5px rgba(0, 0, 0, .04)',
};

export default shadow;
