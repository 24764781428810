/* eslint-disable prefer-destructuring */
// Do not use destructuring!
// https://github.com/zeit/next.js/tree/master#build-time-configuration

const { version } = require('./package.json');

export const MAPBOX_TOKEN = process.env.NEXT_PUBLIC_MAPBOX_TOKEN;
export const GMAPS_API_KEY = process.env.NEXT_PUBLIC_GMAPS_API_KEY;
export const RECAPTCHA_SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;
export const ROOTURL =
  process.env.NEXT_PUBLIC_ROOTURL ||
  `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`;
export const GRAPHQL_URL = `${ROOTURL}/api`;
export const SSR_GRAPHQL_URL = `${process.env.API_URL}/graphql`;
export const WEB_PUSH_PUB = process.env.NEXT_PUBLIC_WEB_PUSH_PUB;
export const FACEBOOK_APP_ID = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID;
export const GOOGLE_OAUTH_CLIENT = process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT;
export const STEEM_API =
  process.env.NEXT_PUBLIC_STEEM_API || 'https://api.steemit.com';
export const HIVE_API =
  process.env.NEXT_PUBLIC_HIVE_API || 'https://api.hive.blog';

export const APP_VERSION = `travelfeed/${version}`;
