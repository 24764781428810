// import Typography from '@mui/material/Typography';
// import Link from 'next/link';
import React, { Component } from 'react';
import { GET_GEOIP } from '../../helpers/graphql/geoIp';
import { CHANGE_SETTINGS } from '../../helpers/graphql/settings';
import graphQLClient from '../../helpers/graphQLClient';
import {
  getCountryCode,
  getUser,
  hasCookieConsent,
  setCookieConsent,
  setCountryCode,
} from '../../helpers/token';
// import CookiePopup from './CookiePopup';

class CookieConsent extends Component {
  state = {
    open: false,
    countryCode: undefined,
  };

  componentDidMount() {
    const hasConsent = hasCookieConsent() === 'true';
    const hasCountry = getCountryCode() !== undefined;
    if (!hasConsent || !hasCountry) {
      graphQLClient(GET_GEOIP)
        .then(({ geoIp }) => {
          const { hasAcceptedCookies } = geoIp;
          fetch('/api/iseu')
            .then(res => {
              res.json().then(res => {
                if (res) {
                  const { isEu, country } = res;
                  this.setState({ countryCode: country });
                  if (hasAcceptedCookies || !isEu) this.autoAccept();
                  else this.setState({ open: !hasConsent });
                }
              });
            })
            .catch(err => console.error(err));
        })
        .catch(err => console.error(err));
    } else this.setState({ open: !hasConsent });
  }

  autoAccept = () => {
    setCookieConsent('true');
    if (this.state.countryCode) setCountryCode(this.state.countryCode);
    this.setState({ open: false });
  };

  accept = () => {
    this.autoAccept();
    if (getUser()) graphQLClient(CHANGE_SETTINGS, { hasAcceptedCookies: true });
  };

  decline = () => {
    this.setState({ open: false });
  };

  render() {
    // const { open } = this.state;
    // if (open === false)
    return <></>;
    // return (
    //   <CookiePopup
    //     open={open}
    //     accept={this.accept}
    //     decline={this.decline}
    //     allowtext="Allow cookies"
    //     // Set containerid only for this consent since some browser
    //     // plugins block this
    //     containerid="cookieconsent"
    //     content={
    //       <Typography variant="body1" className="text-light">
    //         We use cookies to improve your experience and to analyze how our
    //         site is used.
    //         <br />
    //         <Link
    //           color="textPrimary"
    //           href="/about/cookies"
    //           className="text-light text-decoration-underline"
    //         >
    //           Learn more
    //         </Link>
    //       </Typography>
    //     }
    //   />
    // );
  }
}

export default CookieConsent;
