'use client';

import { Command, useCommandState } from 'cmdk';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { POPULAR_ARTICLES } from 'content/helpContent';
import { allHelpPosts } from 'contentlayer/generated';
import { useRouter } from 'next/navigation';
import Highlighter from 'react-highlight-words';
import Fuse from 'fuse.js';
import CmdkModal from './CmdkModal';
import Link from 'next/link';

function CMDKHelper({ showCMDK, setShowCMDK }) {
  const commandListRef = useRef(null);

  return (
    <CmdkModal showCMDK={showCMDK} setShowCMDK={setShowCMDK}>
      <Command label="CMDK" loop shouldFilter={false}>
        <Command.Input
          autoFocus
          onInput={() => {
            // hack to scroll to top of list when input changes (for some reason beyond my comprehension, setTimeout is needed)
            setTimeout(() => {
              commandListRef.current?.scrollTo(0, 0);
            }, 0);
          }}
          placeholder="Search for help articles, guides, and more..."
          className="tw-w-full tw-border-none tw-p-4 tw-font-normal tw-placeholder-gray-400 focus:tw-outline-none focus:tw-ring-0 dark:tw-bg-gray-900 dark:tw-text-gray-100"
        />
        <Command.List
          ref={commandListRef}
          className="tw-h-[50vh] tw-max-h-[360px] tw-min-h-[250px] tw-overflow-scroll tw-border-t tw-border-gray-200 tw-border-solid tw-border-0 tw-p-2 tw-transition-all tw-scrollbar-hide sm:tw-h-[calc(var(--cmdk-list-height)+10rem)]"
        >
          <Command.Empty className="tw-rounded-md tw-bg-gray-100 dark:tw-bg-gray-700 tw-px-4 tw-py-2 tw-text-sm tw-text-gray-600">
            <Link href="/about/contact" legacyBehavior>
              <a onClick={() => setShowCMDK(false)}>
                <div className="tw-flex tw-items-center tw-space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="tw-h-4 tw-w-4 tw-text-gray-400"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.076-4.076a1.526 1.526 0 011.037-.443 48.282 48.282 0 005.68-.494c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                    />
                  </svg>
                  <div className="tw-flex tw-flex-col tw-space-y-1 tw-h-12">
                    <p className="tw-text-sm tw-font-medium tw-text-primary-600 tw-m-0">
                      Contact Support
                    </p>
                    <p className="tw-text-xs tw-text-gray-400 tw-m-0">
                      We are happy to answer your question!
                    </p>
                  </div>
                </div>
              </a>
            </Link>
          </Command.Empty>
          <CommandResults setShowCMDK={setShowCMDK} />
        </Command.List>
      </Command>
    </CmdkModal>
  );
}

const CommandResults = ({ setShowCMDK }) => {
  const router = useRouter();
  const popularArticles = POPULAR_ARTICLES.map(slug =>
    allHelpPosts.find(post => post.slug === slug),
  );

  const allItems = [
    ...allHelpPosts.map(post => ({
      ...post,
      description: post.summary,
    })),
    // get all table of contents headings too
    ...allHelpPosts.flatMap(post => {
      if (post.excludeHeadingsFromSearch) {
        return [];
      }
      return post.tableOfContents.map(toc => ({
        slug: `${post.slug}#${toc.slug}`,
        title: toc.title,
        description: null, // omit description since we don't want to search it
        summary: `In: "${post.title}"`,
      }));
    }),
  ];

  const fuse = useMemo(
    () =>
      new Fuse(allItems, {
        keys: ['title', 'description'],
      }),
    [allItems],
  );

  const search = useCommandState(state => state.search);

  const results = useMemo(() => {
    if (search.length === 0) {
      return popularArticles;
    }
    return fuse.search(search).map(r => r.item);
  }, [search, popularArticles]);

  return results.map(({ slug, title, summary }) => (
    <Command.Item
      key={slug}
      value={title}
      onSelect={() => {
        if (window.location.href.match(/dashboard/)) {
          // this is from the app, open in new tab
          window.open(`https://travelfeed.com/help/article/${slug}`);
        } else {
          router.push(`/help/article/${slug}`);
        }
        setShowCMDK(false);
      }}
      className="tw-group tw-flex tw-cursor-pointer tw-items-center tw-justify-between tw-space-x-2 tw-rounded-md tw-px-4 tw-py-2 hover:tw-bg-gray-100 active:tw-bg-gray-200 aria-selected:tw-bg-gray-100 dark:hover:tw-bg-gray-800 dark:active:tw-bg-gray-700 dark:aria-selected:tw-bg-gray-800"
    >
      <div className="tw-flex tw-flex-col tw-space-y-1">
        <Highlighter
          highlightClassName="tw-underline tw-bg-transparent tw-text-primary-500"
          searchWords={search.split(' ')}
          autoEscape={true}
          textToHighlight={title}
          className="tw-text-sm tw-font-medium tw-text-gray-600 dark:tw-text-gray-100 group-aria-selected:tw-text-primary-600 sm:group-hover:tw-text-primary-600"
        />
        <Highlighter
          highlightClassName="tw-underline tw-bg-transparent tw-text-primary-500"
          searchWords={search.split(' ')}
          autoEscape={true}
          textToHighlight={summary}
          className="tw-line-clamp-1 tw-text-xs tw-text-gray-400"
        />
      </div>
      <div className="tw-shrink-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="tw-invisible tw-h-4 tw-w-4 tw-text-primary-600 group-aria-selected:tw-visible sm:group-hover:tw-visible"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
          />
        </svg>
      </div>
    </Command.Item>
  ));
};

export default function useCMDK() {
  const [showCMDK, setShowCMDK] = useState(false);

  // Toggle the menu when ⌘K is pressed
  useEffect(() => {
    const down = e => {
      const existingModalBackdrop = document.getElementById('modal-backdrop');
      if (e.key === 'k' && (e.metaKey || e.ctrlKey) && !existingModalBackdrop) {
        e.preventDefault();
        setShowCMDK(showCMDK => !showCMDK);
      }
    };

    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, []);

  const CMDK = useCallback(() => {
    return <CMDKHelper showCMDK={showCMDK} setShowCMDK={setShowCMDK} />;
  }, [showCMDK, setShowCMDK]);

  return useMemo(() => ({ showCMDK, setShowCMDK, CMDK }), [
    showCMDK,
    setShowCMDK,
    CMDK,
  ]);
}
