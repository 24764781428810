import { grey, teal } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import Cookie from 'js-cookie';
import shadow from '../helpers/shadow';
import NextLink from 'next/link';
import { forwardRef } from 'react';
import { Figtree } from 'next/font/google';

const figtree = Figtree({ subsets: ['latin'] });

const LinkBehaviour = forwardRef(function LinkBehaviour(props, ref) {
  return <NextLink ref={ref} {...props} />;
});

// Create a theme instance.
export const getTheme = theme => {
  return createTheme({
    typography: {
      fontFamily: figtree.style.fontFamily,
    },
    components: {
      MuiLink: {
        defaultProps: {
          component: LinkBehaviour,
        },
      },
      MuiButtonBase: {
        defaultProps: {
          LinkComponent: LinkBehaviour,
        },
      },
      MuiPaper: {
        styleOverrides: {
          elevation1: {
            boxShadow: shadow.default,
          },
          root: {
            backgroundImage: 'unset',
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          avatar: {
            marginRight: 10,
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          colorTextPrimary: {
            color: theme.paletteType === 'light' ? '#474747' : '#F6F8FA',
          },
          colorTextSecondary: {
            color: theme.paletteType === 'light' ? '#3E454D' : '#F6F8FA',
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            background: theme.paletteType === 'light' ? '#F6F8FA' : '#263238',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          contained: {
            backgroundColor: teal[600],
            '&:hover': {
              backgroundColor: teal[800],
              // Reset on touch devices, it doesn't add specificity
              '@media (hover: none)': {
                backgroundColor: teal[600],
              },
            },
          },
        },
      },
    },
    breakpoints: { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200 },
    palette: {
      mode: theme.paletteType,
      primary: {
        light: theme.paletteType === 'light' ? teal[400] : teal[200],
        main: theme.paletteType === 'light' ? teal[600] : teal[400],
        dark: theme.paletteType === 'light' ? teal[800] : teal[600],
      },
      secondary: {
        light: grey[50],
        main: grey[50],
        dark: grey[50],
      },
      background: {
        default: theme.paletteType === 'light' ? '#F3F4F7' : '#212A2E',
        paper: theme.paletteType === 'light' ? '#FFFFFF' : '#333F45',
        light: theme.paletteType === 'light' ? '#F6F8FA' : '#263238',
        dark: theme.paletteType === 'light' ? grey[50] : '#212A2E',
      },
      text: {
        icon: theme.paletteType === 'light' ? '#474747' : '#fff',
      },
    },
  });
};

const theme = getTheme({
  paletteType: Cookie.get('use_dark_mode') !== 'true' ? 'light' : 'dark',
});

export default theme;
