// https://medium.com/@FezVrasta/service-worker-updates-and-error-handling-with-react-1a3730800e6a
import { supportsPassiveEvents } from 'detect-it';
import React, { useState } from 'react';
import { ADD_PUSH_SUBSCRIPTION } from './graphql/notifications';
import graphQLClient from './graphQLClient';

const ServiceWorkerContext = React.createContext();

export const ServiceWorkerProvider = ({ children }) => {
  const [isUpdateAvailable, setUpdateAvailable] = useState(false);

  React.useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      'serviceWorker' in navigator &&
      window.workbox !== undefined
    ) {
      const wb = window.workbox;

      const promptNewVersionAvailable = () => {
        setUpdateAvailable(true);
      };

      wb.addEventListener('installed', event => {
        if (event.isUpdate) {
          promptNewVersionAvailable();
        }
      });

      // wb.addEventListener('waiting', promptNewVersionAvailable);
      // wb.addEventListener('externalwaiting', promptNewVersionAvailable);

      // ISSUE - this is not working as expected, why?
      // I could only make message event listenser work when I manually add this listenser into sw.js file
      wb.addEventListener(
        'message',
        event => {
          console.log(`Event ${event.type} is triggered.`);
        },
        supportsPassiveEvents ? { passive: true } : false,
      );

      wb.addEventListener(
        'pushsubscriptionchange',
        event => {
          console.log('Subscription expired');
          event.waitUntil(
            navigator.serviceWorker.ready.then(reg => {
              reg.pushManager.getSubscription().then(sub => {
                if (
                  sub &&
                  !(
                    sub.expirationTime &&
                    Date.now() > sub.expirationTime - 5 * 60 * 1000
                  )
                ) {
                  const variables = {
                    pushSubscription: JSON.stringify(sub),
                  };
                  graphQLClient(ADD_PUSH_SUBSCRIPTION, variables).then(() =>
                    console.log(
                      'Submitted push notification subscription to API',
                    ),
                  );
                }
              });
            }),
          );
        },
        supportsPassiveEvents ? { passive: true } : false,
      );

      wb.register();
    }
  }, []);

  const value = React.useMemo(
    () => ({
      isUpdateAvailable,
      updateAssets: () => {
        if (
          typeof window !== 'undefined' &&
          'serviceWorker' in navigator &&
          window.workbox !== undefined
        ) {
          console.log('updating assets');
          const wb = window.workbox;
          wb.addEventListener(
            'controlling',
            event => {
              console.log(event);
              window.location.reload();
            },
            supportsPassiveEvents ? { passive: true } : false,
          );
          // Send a message to the waiting service worker, instructing it to activate.
          wb.messageSW({ type: 'SKIP_WAITING' });
        }
      },
    }),
    [isUpdateAvailable],
  );

  return (
    <ServiceWorkerContext.Provider value={value}>
      {children}
    </ServiceWorkerContext.Provider>
  );
};

// With this React Hook we'll be able to access `isUpdateAvailable` and `updateAssets`
export const useServiceWorker = () => {
  return React.useContext(ServiceWorkerContext);
};
