import { indigo, teal } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from 'react';
import { useServiceWorker } from '../../helpers/useServiceWorkerUpdate';

const UpdatePwaNotice = () => {
  const [open, setOpen] = useState(true);
  const [color2, setColor2] = useState(700);

  const { isUpdateAvailable, updateAssets } = useServiceWorker();

  const handleUpdateClick = () => {
    setOpen(false);
    updateAssets();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {open && isUpdateAvailable && (
        <div
          style={{
            position: 'fixed',
            bottom: '0px',
            left: '0px',
            zIndex: 1299,
          }}
        >
          <div className="container w-100">
            <div className="row">
              <div
                style={{ width: '20px' }}
                className="d-none d-xl-block d-lg-block d-md-block d-sm-block"
              />
              <div
                className="popup col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 text-light p-3"
                style={{ background: indigo[700] }}
              >
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    padding: '2px',
                  }}
                >
                  <IconButton
                    onClick={handleClose}
                    className="text-light"
                    aria-label="Close"
                    size="large"
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
                <Typography variant="h6" className="text-light">
                  Update available
                </Typography>
                <Typography variant="body1" className="text-light">
                  An update for TravelFeed is available.
                </Typography>
              </div>
            </div>
            <div className="row">
              <div
                style={{ width: '20px' }}
                className="d-none d-xl-block d-lg-block d-md-block d-sm-block"
              />
              <div className="popup col-xl-8 col-lg8 col-md-8 col-sm-8 col-12 p-0">
                <div
                  onClick={handleUpdateClick}
                  onKeyDown={handleUpdateClick}
                  className="cpointer text-light text-center font-weight-bold p-2 w-100"
                  style={{ background: teal[color2] }}
                  onMouseEnter={() => setColor2(800)}
                  onMouseLeave={() => setColor2(700)}
                  role="button"
                  tabIndex={0}
                >
                  <Typography variant="button">Update now</Typography>
                </div>
              </div>
              <div
                style={{ height: '20px' }}
                className="d-none d-xl-block d-lg-block d-md-block d-sm-block col-12 text-light"
              />
            </div>
          </div>
        </div>
      )}
      <style jsx global>{`
        .popup {
          min-width: 300px;
        }
        @media (max-width: 576px) {
          .popup {
            min-width: 100vw;
          }
        }
      `}</style>
    </>
  );
};

export default UpdatePwaNotice;
