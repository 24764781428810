import Cookie from 'js-cookie';
import jwtDecode from 'jwt-decode';

export const getRoles = () => {
  const token = Cookie.get('csrf');
  if (token === undefined) {
    return undefined;
  }
  const jwt = jwtDecode(token);
  return jwt.roles ? jwt.roles : [];
};

export const getUser = () => {
  const token = Cookie.get('csrf');
  if (token === undefined) {
    return undefined;
  }
  const jwt = jwtDecode(token);
  return jwt.username;
};

export const getCountryCode = () => {
  return Cookie.get('countryCode');
};

export const setCountryCode = cc => {
  // Set cookie with one week expiration
  const expires = new Date(new Date().getTime() + 3600 * 1000 * 24 * 7);
  Cookie.set('countryCode', cc, { expires });
};

export const hasCookieConsent = () => {
  return Cookie.get('cookie_consent');
};

export const setCookieConsent = consent => {
  // Set cookie with one year expiration
  const expires = new Date(new Date().getTime() + 3600 * 1000 * 24 * 365);
  Cookie.set('cookie_consent', consent, { expires });
};

export const deleteCookieConsent = () => {
  Cookie.remove('cookie_consent');
};
