import { gql } from '@apollo/client';

export const SET_LINKED_ACCOUNTS = gql`
  mutation setLinkedAccounts(
    $isHive: Boolean
    $isSteem: Boolean
    $token: String
  ) {
    setLinkedAccounts(isHive: $isHive, isSteem: $isSteem, token: $token) {
      success
      message
    }
  }
`;

export const GET_LINKED_ACCOUNTS = gql`
  query preferences {
    preferences {
      postToHive
      hiveUser
      postToSteem
      steemUser
    }
  }
`;

export const CHANGE_SETTINGS = gql`
  mutation updatePreferences(
    $trackFollows: Boolean
    $trackMentions: Boolean
    $trackReplies: Boolean
    $trackCuration: Boolean
    $trackUpdates: Boolean
    $useDarkMode: Boolean
    $hasAcceptedCookies: Boolean
    $useAdvancedEditorOptions: Boolean
    $claimRewards: Boolean
    $hideEditorTutorial: Boolean
  ) {
    updatePreferences(
      trackFollows: $trackFollows
      trackMentions: $trackMentions
      trackReplies: $trackReplies
      trackCuration: $trackCuration
      trackUpdates: $trackUpdates
      useDarkMode: $useDarkMode
      hasAcceptedCookies: $hasAcceptedCookies
      useAdvancedEditorOptions: $useAdvancedEditorOptions
      claimRewards: $claimRewards
      hideEditorTutorial: $hideEditorTutorial
    ) {
      success
      message
    }
  }
`;

export const USE_ADVANCED_EDITOR_OPTIONS = gql`
  query preferences {
    preferences {
      useAdvancedEditorOptions
      hideEditorTutorial
    }
  }
`;

export const GET_SETTINGS = gql`
  query preferences {
    preferences {
      useDarkMode
      trackFollows
      trackMentions
      trackReplies
      trackCuration
      trackUpdates
      useAdvancedEditorOptions
      claimRewards
      hasApiKey
    }
  }
`;
