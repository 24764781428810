/* eslint-disable import/no-unused-modules */
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ThemeProvider } from '@mui/material/styles';
import Cookie from 'js-cookie';
import { SnackbarProvider } from 'notistack';
import React, { useEffect, useState } from 'react';
import NextProgress from 'next-progress';
import CookieConsent from '../components/CookieConsent/CookieConsent';
import UpdatePwaNotice from '../components/General/UpdatePwaNotice';
import UserContext from '../components/General/UserContext';
import { ServiceWorkerProvider } from '../helpers/useServiceWorkerUpdate';
import { init } from '../lib/sentry';
import { getTheme } from '../lib/theme';
import useCMDK from 'lib/cmdk';
import { createEmotionSsrAdvancedApproach } from 'tss-react/next/pagesDir';
import PlausibleProvider from 'next-plausible';
import '../styles/bootstrap.min.css';
import '../styles/style.css';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
config.autoAddCss = false;

init();

const {
  augmentDocumentWithEmotionCache,
  withAppEmotionCache,
} = createEmotionSsrAdvancedApproach({ key: 'css' });

export { augmentDocumentWithEmotionCache };

function MyApp({ Component, pageProps }) {
  const [theme, setTheme] = useState(undefined);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  function applyUndefinedTheme(e) {
    if (Cookie.get('use_dark_mode') !== undefined) return;
    if (e && e.matches) {
      document.querySelector('html').classList.add('tw-dark');
    } else {
      document.querySelector('html').classList.remove('tw-dark');
    }
  }

  useEffect(() => {
    const darkModeCookie = Cookie.get('use_dark_mode');
    if (darkModeCookie !== undefined) {
      const newTheme = darkModeCookie !== 'true' ? 'light' : 'dark';
      if (darkModeCookie !== 'true')
        document.querySelector('html').classList.remove('tw-dark');
      else document.querySelector('html').classList.add('tw-dark');
      setTheme(newTheme);
    } else if (theme === undefined) {
      const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      applyUndefinedTheme(mediaQuery);
      mediaQuery.addEventListener('change', applyUndefinedTheme);
      return () =>
        mediaQuery.removeEventListener('change', applyUndefinedTheme);
    }

    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  }, []);

  const setDarkMode = () => {
    const expires = new Date(new Date().getTime() + 3600 * 1000 * 24 * 30);
    Cookie.set('use_dark_mode', true, { expires });
    document.querySelector('html').classList.add('tw-dark');
    setTheme('dark');
  };

  const setLightMode = () => {
    const expires = new Date(new Date().getTime() + 3600 * 1000 * 24 * 30);
    Cookie.set('use_dark_mode', false, { expires });
    document.querySelector('html').classList.remove('tw-dark');
    setTheme('light');
  };

  const systemDarkMode = prefersDarkMode ? 'dark' : 'light';

  const { CMDK, setShowCMDK } = useCMDK();

  return (
    <PlausibleProvider domain="travelfeed.com" selfHosted trackOutboundLinks>
      <UserContext.Provider
        value={{
          theme: theme !== undefined ? theme : systemDarkMode,
          setDarkMode,
          setLightMode,
          setShowCMDK,
          // React Hooks: https://reacttricks.com/sharing-global-data-in-next-with-custom-app-and-usecontext-hook/
        }}
      >
        <ServiceWorkerProvider>
          <ThemeProvider
            theme={getTheme({
              paletteType: theme !== undefined ? theme : systemDarkMode,
            })}
          >
            <CssBaseline />
            {/* Pass pageContext to the _document though the renderPage enhancer
                to render collected styles on server side. */}
            <SnackbarProvider maxSnack={3}>
              <CMDK />
              <UpdatePwaNotice />
              <CookieConsent />
              <NextProgress delay={200} options={{ showSpinner: false }} />
              <Component {...pageProps} />
            </SnackbarProvider>
          </ThemeProvider>
        </ServiceWorkerProvider>
      </UserContext.Provider>
    </PlausibleProvider>
  );
}

export default withAppEmotionCache(MyApp);
